<template>
  <form-field
    :name="field.name"
    :hide="field.hide"
  >
    <v-text-field
      v-model="fileName"
      v-bind="field.props"
      @click="onPickFile"
      prepend-icon="attach_file"
      box
    />
    <input
      type="file"
      style="display: none"
      ref="fileInput"
      accept="image/*"
      @change="onFilePicked"
    >
    <v-img
      :src="url"
      contain
      max-height="300"
    />
    <!-- <v-btn
      color="primary"
      @click.stop="onUploadSelectedFileClick"
      :loading="loading"
    >
      UPLOAD
    </v-btn> -->
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  data () {
    return {
      fileName: '',
      url: '',
      fileObject: null,
      cardResult: '',
      name: '',
      size: '',
      type: '',
      lastModifiedDate: '',
      loading: false,
    }
  },
  methods: {
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      const files = event.target.files
      if (files[0] !== undefined) {
        this.fileName = files[0].name
        // Check validity of file
        if (this.fileName.lastIndexOf('.') <= 0) {
          return
        }
        // If valid, continue
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.url = fr.result
          this.field.value = this.url
          this.fileObject = files[0] // this is an file that can be sent to server...
        })
      } else {
        this.fileName = ''
        this.fileObject = null
        this.url = ''
      }
    },
    // onUploadSelectedFileClick () {
    //   this.loading = true

    //   console.log(this.url)
    //   // A file is not chosen!
    //   if (!this.fileObject) {
    //     alert('No file!!')
    //   }
    //   // DO YOUR JOB HERE with fileObjectToUpload
    //   // https://developer.mozilla.org/en-US/docs/Web/API/File/File
    //   this.name = this.fileObject.name
    //   this.size = this.fileObject.size
    //   this.type = this.fileObject.type
    //   this.lastModifiedDate = this.fileObject.lastModifiedDate
    //   // DO YOUR JOB HERE with fileObjectToUpload
    //   this.loading = false
    // },
  },
}

</script>
